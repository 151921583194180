import React, { FunctionComponent } from "react";
import "./styles.scss";
import Section from "../../Section";
import RichText from "../../RichText";
import CtaBlock from "../../CtaBlock";

interface BluePrintGrantInterface {
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  _rawDescription: any;
  title: string;
  ctaLink: any;
}

const BluePrintGrant: FunctionComponent<BluePrintGrantInterface> = props => {
  const { palleteColorList, _rawDescription, title, ctaLink } = props;
  return (
    <Section sectionClassName="c-bluePrint-grant" theme={palleteColorList?.bgColor.title}>
      <div className="c-bluePrint-grant__content">
        <div className="grid-row">
          <div className="grid-col grid-col-md-3 col-border border-right-only d-flex align-items-center">
            <h2 className="c-bluePrint-grant__title">{title}</h2>
          </div>
          <div className="grid-col grid-col-md-9 col-border">
            <div className="c-bluePrint-grant__bodytext">
              {_rawDescription ? <RichText data={_rawDescription} /> : null}
              {ctaLink?.ctaLabel ? (
                <div className="c-bluePrint-grant__cta">
                  <CtaBlock ctaBlock={ctaLink} ctaLabel={ctaLink.ctaLabel} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default BluePrintGrant;
